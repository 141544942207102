import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthenticateService } from './authenticate.service';

@NgModule({
	imports: [CommonModule, HttpClientModule],
	providers: [AuthenticateService],
})
export class ProviderAuthenticateModule {}
