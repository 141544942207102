<nav
	class="hidden h-full grow flex-col justify-between gap-4 bg-white p-4 pt-3 shadow-none sm:flex"
>
	<div class="flex flex-col gap-1">
		<div
			class="relative flex items-center gap-2 px-4 py-2 font-bold text-purple-500"
		>
			<ui-icon name="person" [outlined]="true"></ui-icon>
			<span
				*ngIf="deceasedNameComputed()"
				class="overflow-hidden text-ellipsis whitespace-nowrap text-xs"
				[title]="deceasedNameComputed()"
			>
				{{ deceasedNameComputed() }}
			</span>
			<span
				class="absolute bottom-3/4 right-0 rounded bg-yellow-500 px-1 text-xxs font-normal"
				*ngIf="accountSig().data?.isPremium"
			>
				{{ 'global.premium-account-status-label' | translate }}
			</span>
		</div>
		<a
			class="block rounded-input px-4 py-2 pl-10 text-xs text-purple-500 hover:bg-blue-50"
			[routerLink]="['/' + routeNames.TASKS]"
			routerLinkActive="text-blue-500 bg-blue-50 font-bold"
		>
			<span class="text-xs">{{
				'navigation.tasks-label' | translate
			}}</span>
		</a>
		<a
			class="block rounded-input px-4 py-2 pl-10 text-xs text-purple-500 hover:bg-blue-50"
			[routerLink]="['/' + routeNames.DECEASED]"
			routerLinkActive="text-blue-500 bg-blue-50 font-bold"
		>
			<span class="text-xs">{{
				'navigation.data-label' | translate
			}}</span>
		</a>
		<a
			*ngIf="false"
			class="block rounded-input px-4 py-2 pl-10 text-xs text-purple-500 hover:bg-blue-50"
			[routerLink]="['/' + routeNames.INTAKE]"
			routerLinkActive="text-blue-500 bg-blue-50 font-bold"
		>
			<span class="text-xs">{{
				'navigation.documents-label' | translate
			}}</span>
		</a>
		<a
			*ngIf="false"
			class="block rounded-input px-4 py-2 pl-10 text-xs text-purple-500 hover:bg-blue-50"
			[routerLink]="['/' + routeNames.ACCOUNT]"
			routerLinkActive="text-blue-500 bg-blue-50 font-bold"
		>
			<span class="text-xs">{{
				'navigation.account-label' | translate
			}}</span>
		</a>
	</div>
	<div class="flex flex-col gap-2">
		<a
			[routerLink]="['/' + routeNames.ACCOUNT]"
			class="flex items-center gap-2 rounded-input p-4 py-2 font-bold text-purple-500 hover:bg-blue-50"
			routerLinkActive="text-purple-700 bg-blue-50"
		>
			<ui-icon name="settings" [outlined]="true"></ui-icon>
			<span class="text-xs" *ngIf="accountNameComputed()">{{
				accountNameComputed()
			}}</span>
			<span class="text-xs" *ngIf="!accountNameComputed()">
				{{ 'navigation.account-info-label' | translate }}
			</span>
		</a>
		<ui-button
			*ngIf="!accountSig().data?.isPremium"
			[styleType]="buttonStyleTypes.CTA"
			[size]="buttonSizes.XS"
			[fullWidth]="true"
			(click)="isPremiumModalOpenSig.set(true)"
		>
			<div class="flex items-center justify-center gap-2">
				<ui-icon
					name="workspace_premium"
					[outlined]="true"
					class="text-base"
				></ui-icon>
				{{ 'navigation.become-premium-action' | translate }}
			</div>
		</ui-button>
		<ui-button
			[styleType]="buttonStyleTypes.STROKE"
			[size]="buttonSizes.XS"
			[fullWidth]="true"
			(click)="logout()"
		>
			{{ 'navigation.logout-action' | translate }}
		</ui-button>
	</div>
</nav>
<!-- @TODO: Update to `grid-cols-4` when extra menu item is added -->
<nav
	class="grid grid-flow-col grid-cols-3 gap-2 rounded-t-menu bg-white p-3 shadow-menu sm:hidden"
>
	<a
		class="flex flex-wrap items-center justify-center gap-2 rounded-input p-2 text-center"
		[routerLink]="['/' + routeNames.TASKS]"
		routerLinkActive="text-blue-500"
	>
		<div class="w-full self-start text-2xl leading-none">
			<ui-icon name="task_alt"></ui-icon>
		</div>
		<span class="text-xs">{{ 'navigation.tasks-label' | translate }}</span>
	</a>
	<a
		class="flex flex-wrap items-center justify-center gap-2 rounded-input p-2 text-center"
		[routerLink]="['/' + routeNames.DECEASED]"
		routerLinkActive="text-blue-500"
	>
		<div class="w-full self-start text-2xl leading-none">
			<ui-icon name="contact_emergency" [outlined]="true"></ui-icon>
		</div>
		<span class="text-xs">{{ 'navigation.data-label' | translate }}</span>
	</a>
	<a
		*ngIf="false"
		class="flex flex-wrap items-center justify-center gap-2 rounded-input p-2 text-center"
		[routerLink]="['/' + routeNames.INTAKE]"
		routerLinkActive="text-blue-500"
	>
		<div class="w-full self-start text-2xl leading-none">
			<ui-icon name="folder" [outlined]="true"></ui-icon>
		</div>
		<span class="text-xs">{{
			'navigation.documents-label' | translate
		}}</span>
	</a>
	<a
		class="flex flex-wrap items-center justify-center gap-2 rounded-input p-2 text-center"
		[routerLink]="['/' + routeNames.ACCOUNT]"
		routerLinkActive="text-blue-500"
	>
		<div class="w-full self-start text-2xl leading-none">
			<ui-icon name="menu"></ui-icon>
		</div>
		<span class="text-xs">{{
			'navigation.account-label' | translate
		}}</span>
	</a>
</nav>
