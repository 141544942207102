import { Environment } from './environment.interface';
import { version } from './version';

export const environment: Environment = {
	version: version,
	apiBaseUrl: 'https://dashboard-api.vaarwel.nl/',
	gtmId: 'GTM-NX389RV',
	apm: {
		url: 'https://apm.newstory.cloud',
		serviceName: 'dashboard',
		environment: 'LIVE',
		origins: [
			'https://dashboard.vaarwel.nl/',
			'https://dashboard-api.vaarwel.nl/',
		],
		labels: {
			group: 'vaarwel',
		},
	},
};
