import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UiButtonModule } from 'ui-button';
import { UiIconModule } from 'ui-icon';
import { NavigationComponent } from './navigation.component';

@NgModule({
	declarations: [NavigationComponent],
	exports: [NavigationComponent],
	imports: [
		CommonModule,
		RouterModule.forChild([]),
		TranslateModule.forChild(),
		UiIconModule,
		UiButtonModule,
	],
})
export class NavigationModule {}
