import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AccountService } from './account.service';

@NgModule({
	imports: [CommonModule, HttpClientModule],
	providers: [AccountService],
})
export class ProviderAccountModule {}
