import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ProviderDeceasedModule } from 'provider-deceased/lib/deceased.module';
import { UiButtonModule } from 'ui-button';
import { UiDialogModule } from 'ui-dialog';
import { UiIconModule } from 'ui-icon';
import { UiInputModule } from 'ui-input/lib/ui-input.module';
import { UiModalModule } from 'ui-modal/lib/ui-modal.module';
import { PremiumModalComponent } from './premium-modal.component';

@NgModule({
	declarations: [PremiumModalComponent],
	exports: [PremiumModalComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		UiButtonModule,
		UiDialogModule,
		UiIconModule,
		UiInputModule,
		UiModalModule,
		ProviderDeceasedModule,
	],
})
export class PremiumModalModule {}
