<ng-template #logoWithoutAnchor>
	<img
		class="mx-auto"
		[ngClass]="{
			'h-6 sm:h-12': hasMenu,
			'h-20': !hasMenu
		}"
		src="assets/images/logo.svg"
		[alt]="'global.logo-alt-text' | translate"
	/>
</ng-template>
<div
	class="flex h-full flex-col bg-mint-100 py-safe sm:grid sm:grid-cols-[200px_1fr] sm:grid-rows-[max-content_1fr] lg:grid-cols-[288px_1fr]"
>
	<div
		*ngIf="hasHeader"
		class="col-span-2 sm:bg-white sm:py-2"
		[ngClass]="{
			'w-full py-1 sm:col-span-1 ': hasMenu,
			'bg-white py-4': !hasMenu
		}"
	>
		<a
			href="https://www.vaarwel.nl"
			[title]="'global.to-vaarwel-title' | translate"
			target="_blank"
			*ngIf="hasMenu; else logoWithoutAnchor"
		>
			<img
				class="mx-auto"
				[ngClass]="{
					'h-6 sm:h-12': hasMenu,
					'h-20': !hasMenu
				}"
				src="assets/images/logo.svg"
				[alt]="'global.logo-alt-text' | translate"
			/>
		</a>
	</div>
	<div
		#scrollContainer
		class="col-span-2 flex-1 sm:order-3 sm:row-span-2 sm:overflow-auto"
		[ngClass]="{
			'sm:col-span-1': hasMenu
		}"
	>
		<router-outlet></router-outlet>
	</div>
	<div
		*ngIf="hasMenu"
		class="sticky bottom-0 col-span-2 row-start-auto sm:relative sm:bottom-auto sm:order-2 sm:col-span-1 sm:row-start-2 sm:bg-white"
	>
		<dashboard-navigation></dashboard-navigation>
	</div>
</div>
<dashboard-premium-modal></dashboard-premium-modal>
