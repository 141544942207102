import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApmHelper } from '../../helpers/apm/apm.helper';

/**
 * Interceptor that adds a trace state header to the request
 */
@Injectable()
export class TraceContextInterceptor implements HttpInterceptor {
	constructor(private readonly apmHelper: ApmHelper) {}

	intercept<T>(
		request: HttpRequest<T>,
		next: HttpHandler,
	): Observable<HttpEvent<T>> {
		const modifiedRequest: HttpRequest<T> = request.clone({
			headers: request.headers.set(
				'tracestate',
				this.apmHelper.getTraceContext(),
			),
		});
		return next.handle(modifiedRequest);
	}
}
