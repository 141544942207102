import { Injectable } from '@angular/core';
import { ApmService } from '@elastic/apm-rum-angular';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppInitializeService {
	constructor(private readonly apmService: ApmService) {}

	init() {
		this.initializeAPM();
	}

	private initializeAPM() {
		if (!environment.apm) {
			return;
		}

		const apm = this.apmService.init({
			breakdownMetrics: true,
			distributedTracingOrigins: environment.apm.origins,
			environment: environment.apm.environment,
			pageLoadTransactionName: window.location.href,
			serverUrl: environment.apm.url,
			serviceName: environment.apm.serviceName,
			serviceVersion: environment.version,
		});

		apm.addLabels(environment.apm.labels);

		const originalError = console.error;
		console.error = (error: string | Error): void => {
			try {
				if (typeof error === 'string' || error instanceof Error) {
					apm.captureError(error);
				}
			} finally {
				originalError.apply(error);
			}
		};
	}
}
