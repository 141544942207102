<ui-modal
	[isOpen]="isPremiumModalOpenSig()"
	headerStyleModifiers="absolute w-full"
	(byClose)="closeIntro()"
>
	<div class="flex flex-col gap-6">
		<div class="mt-6 text-center">
			<ui-icon
				class="text-9xl leading-none text-yellow-500"
				name="workspace_premium"
				[outlined]="true"
			></ui-icon>
		</div>
		<h2 class="text-2xl font-bold text-purple-500">
			{{ 'premium-modal.intro.title' | translate }}
		</h2>
		<p
			class="prose"
			[innerHTML]="'premium-modal.intro.content' | translate"
		></p>
		<div *ngIf="premiumStateSig().state === loadingStates.ERROR">
			<ui-dialog
				[type]="dialogTypes.ERROR"
				[title]="
					'premium-modal.intro.activate-error-feedback-title'
						| translate
				"
				[text]="
					'premium-modal.intro.activate-error-feedback-text'
						| translate
				"
			></ui-dialog>
		</div>
		<div class="flex flex-col gap-2">
			<ui-button
				[styleType]="buttonStyleTypes.CTA"
				[fullWidth]="true"
				(click)="startPaymentFlow()"
			>
				{{ 'premium-modal.intro.order-action' | translate }}
			</ui-button>
			<ui-button
				[size]="buttonSizes.XS"
				[styleType]="buttonStyleTypes.LINK"
				[fullWidth]="true"
				(click)="openForm()"
			>
				{{ 'premium-modal.intro.redeem-action' | translate }}
			</ui-button>
			<p
				class="prose prose-sm"
				*ngIf="'premium-modal.intro.conditions' | translate"
				[innerHTML]="'premium-modal.intro.conditions' | translate"
			></p>
		</div>
	</div>
</ui-modal>
<ui-modal
	[isOpen]="isRedeemPremiumModalOpenSig()"
	[mobileHeaderTitle]="'premium-modal.redeem.title' | translate"
	(byClose)="closeForm()"
>
	<form #premiumForm="ngForm" (submit)="submitPremiumForm($event)">
		<div class="flex flex-col gap-6">
			<div class="flex max-w-xs flex-col gap-0.5">
				<div>
					<label class="text-sm font-medium" for="code">
						{{ 'global.premium-code-label' | translate }}
					</label>
				</div>
				<ui-input
					#code="ngModel"
					name="code"
					identifier="code"
					[ngModel]="premiumCodeControlDataSig()"
					(ngModelChange)="premiumCodeControlDataSig.set($event)"
					[showInvalid]="true"
					required
				></ui-input>
				<label
					*ngIf="premiumForm.submitted && code.errors"
					class="flex items-start gap-1 text-xs text-feedback-error-500"
					for="name"
				>
					<ui-icon
						name="error"
						class="text-base leading-none text-feedback-error-500"
					></ui-icon>
					<span>
						{{
							'global.premium-code-invalid-feedback-message'
								| translate
						}}
					</span>
				</label>
			</div>
			<div *ngIf="premiumStateSig().state === loadingStates.ERROR">
				<ui-dialog
					*ngIf="
						premiumStateSig().error ===
						deceasedErrorCodes.PREMIUM_CODE_INVALID
					"
					[type]="dialogTypes.ERROR"
					[title]="
						'premium-modal.intro.redeem-invalid-feedback-title'
							| translate
					"
					[text]="
						'premium-modal.intro.redeem-invalid-feedback-text'
							| translate
					"
				></ui-dialog>
				<ui-dialog
					*ngIf="
						premiumStateSig().error ===
						deceasedErrorCodes.PREMIUM_CODE_EXPIRED
					"
					[type]="dialogTypes.ERROR"
					[title]="
						'premium-modal.intro.redeem-expired-feedback-title'
							| translate
					"
					[text]="
						'premium-modal.intro.redeem-expired-feedback-text'
							| translate
					"
				></ui-dialog>
				<ui-dialog
					*ngIf="
						premiumStateSig().error === deceasedErrorCodes.UNKNOWN
					"
					[type]="dialogTypes.ERROR"
					[title]="
						'premium-modal.intro.redeem-error-feedback-title'
							| translate
					"
					[text]="
						'premium-modal.intro.redeem-error-feedback-text'
							| translate
					"
				></ui-dialog>
			</div>
			<div class="flex flex-col gap-2">
				<ui-button
					[styleType]="buttonStyleTypes.PRIMARY"
					[fullWidth]="true"
					[isSubmit]="true"
					[isDisabled]="
						premiumStateSig().state === loadingStates.LOADING
					"
				>
					{{ 'premium-modal.redeem.add-action' | translate }}
				</ui-button>
				<ui-button
					[styleType]="buttonStyleTypes.LINK"
					[fullWidth]="true"
					[isDisabled]="
						premiumStateSig().state === loadingStates.LOADING
					"
					(click)="closeForm()"
				>
					{{ 'premium-modal.redeem.cancel-action' | translate }}
				</ui-button>
			</div>
		</div>
	</form>
</ui-modal>
