import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticateErrorCodes } from '@nx/core/lib/interfaces/authenticate.interface';
import { AuthenticateService } from 'provider-authenticate/lib/authenticate.service';
import { Observable, catchError, throwError } from 'rxjs';
import { RouteNames } from '../../app.routes';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private readonly authService: AuthenticateService,
		private router: Router,
	) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		return this.handleRequest(
			next,
			this.updateHeadersAndReturnRequest(
				request,
				this.authService.authenticateStateComputed().data?.token,
			),
		);
	}

	private handleRequest(
		next: HttpHandler,
		request: HttpRequest<unknown>,
	): Observable<never | HttpEvent<unknown>> {
		return next.handle(request).pipe(
			catchError((error) => {
				if (
					error instanceof HttpErrorResponse &&
					error.status === 401
				) {
					this.authService.logout(
						AuthenticateErrorCodes.UNAUTHORIZED,
					);
					this.router.navigate(['/' + RouteNames.LOGIN], {
						state: {
							sessionInvalid: true,
						},
					});
				}
				return throwError(() => error);
			}),
		);
	}

	private updateHeadersAndReturnRequest(
		request: HttpRequest<unknown>,
		token: string | undefined,
	): HttpRequest<unknown> {
		if (token) {
			request = request.clone({
				setHeaders: { Authorization: `Bearer ${token}` },
			});
		}
		return request;
	}
}
