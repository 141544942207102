import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

export const APM_TRACE_CONTEXT_KEY = '__apm_trace_context__';

@Injectable({
	providedIn: 'root',
})
export class ApmHelper {
	getTraceContext(prefix = 'dashboard'): string {
		let tracestate: string | null = sessionStorage.getItem(
			APM_TRACE_CONTEXT_KEY,
		);
		if (!tracestate) {
			tracestate = this.createTraceContext(prefix);
			sessionStorage.setItem(APM_TRACE_CONTEXT_KEY, tracestate);
		}
		return tracestate;
	}

	private createTraceContext(prefix: string): string {
		return `${prefix}=${uuidv4()}`;
	}
}
