import { Component, ViewEncapsulation, computed } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'provider-account/lib/account.service';
import { AuthenticateService } from 'provider-authenticate/lib/authenticate.service';
import { QuestionnaireService } from 'provider-questionnaire/lib/questionnaire.service';
import { ButtonSizes, ButtonStyleTypes } from 'ui-button';
import { RouteNames } from '../../app.routes';
import { PremiumModalService } from '../../services/premium-modal/premium-modal.helper';

@Component({
	selector: 'dashboard-navigation',
	templateUrl: './navigation.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class NavigationComponent {
	readonly routeNames = RouteNames;
	readonly buttonStyleTypes = ButtonStyleTypes;
	readonly buttonSizes = ButtonSizes;

	accountSig = this.accountService.accountStateComputed;
	readonly accountNameComputed = computed<string>(() => {
		const accountData = this.accountSig().data;
		if (accountData && (accountData.firstname || accountData.lastname)) {
			return `${accountData.firstname} ${accountData.lastname}`.trim();
		}
		return '';
	});
	readonly deceasedNameComputed = computed<string>(() => {
		const deceasedData = this.accountSig().data?.deceaseds[0];
		if (deceasedData && (deceasedData.firstname || deceasedData.lastname)) {
			return `${deceasedData.firstname} ${deceasedData.lastname}`.trim();
		}
		return '';
	});
	isPremiumModalOpenSig = this.premiumModalService.isModalOpenSig;

	constructor(
		private readonly router: Router,
		private readonly authService: AuthenticateService,
		private readonly accountService: AccountService,
		private readonly questionnaireService: QuestionnaireService,
		private readonly premiumModalService: PremiumModalService,
	) {}

	logout() {
		this.authService.logout();
		this.accountService.resetAccountState();
		this.questionnaireService.resetQuestionnaireState();
		this.router.navigate([`/${RouteNames.LOGIN}`]);
	}
}
